define("discourse/plugins/discourse-google-one-tap/discourse/templates/connectors/after-login-buttons/google-one-tap", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="btn-social" id="google_one_tap"></div>
  */
  {
    "id": "RqBrDF7i",
    "block": "[[[10,0],[14,0,\"btn-social\"],[14,1,\"google_one_tap\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-google-one-tap/discourse/templates/connectors/after-login-buttons/google-one-tap.hbs",
    "isStrictMode": false
  });
});